"use client";

import { useEffect } from "react";

interface IEnforceHttpsProps {}
const EnforceHttps: React.FC<IEnforceHttpsProps> = () => {
	useEffect(() => {
		if (window.location.hostname === "localhost" || process.env.NEXT_PUBLIC_HTTPS_NO_ENFORCE) {
			return;
		}
		if (window.location.protocol !== "https:") {
			window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
		}
	}, []);

	return <></>;
};

export default EnforceHttps;
